<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div
            class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between
            "
          >
            <h4 class="mb-sm-0">Marketing</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item active">Daftar Marketing</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">Daftar Marketing</h4>
              <router-link
                :to="{ name: 'marketing.form' }"
                type="button"
                class="btn btn-primary waves-effect waves-light ms-2"
                v-if="permission.can('user create')"
              >
                <i class="bx bx-plus font-size-16 align-middle me-2"></i>
                Marketing
              </router-link>
            </div>

            <div class="card-body">
              <DataTable
                :url="dataTableUrl"
                :columns="columnHeaders"
                :actions="actions"
                :reload="reloadDataTable"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "../../components/datatable/DataTable.vue";
import { endpoint } from "../../host";
import permissonService from "../../services/permisson-service";

export default {
  components: {
    DataTable,
  },

  created() {},

  data() {
    return {
      permission: permissonService,
      reloadDataTable: false,
      dataTableUrl: endpoint.marketing,
      actions: [
      ],
      columnHeaders: [
        { text: "Kode Referal", name: "referal_code" },
        { text: "Nama", name: "user.name" },
        { text: "Email", name: "user.email" },
      ],
    };
  },
};
</script>

<style>
</style>